export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  UseGlobalCssForOneColumnCart: 'specs.stores.UseGlobalCssForOneColumnCart',
  HideCountryPickerInCart: 'specs.stores.HideCountryPickerInCart',
  GetCartWithConsentRequiredPaymentPolicyOnLineItem: 'specs.stores.GetCartWithConsentRequiredPaymentPolicyOnLineItem',
  SupportCardTokenizationOnCartAndCheckout: 'specs.stores.SupportCardTokenizationOnCartAndCheckout',
  UseDeleteIconOnLineItems: 'specs.stores.UseDeleteIconOnLineItems',
  UseThreeDotsLoaderOnCheckoutButton: 'specs.stores.UseThreeDotsLoaderOnCheckoutButton',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldPreloadCurrentCart: 'specs.stores.ShouldPreloadCurrentCart',
  ShouldUseNewCartQueryAndMutations: 'specs.stores.ShouldUseNewCartQueryAndMutations',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  Use20PaddingForCart: 'specs.stores.Use20PaddingForCart',
  UseNewCartStyles: 'specs.stores.UseNewCartStyles',
  AddLoaderWhenUpdateCart: 'specs.stores.AddLoaderWhenUpdateCart',
  UseChevronForItemOptions: 'specs.stores.UseChevronForItemOptions',
  FixCartQuantityA11y: 'specs.stores.FixCartQuantityA11y',
  MakeCartItemNameLink: 'specs.stores.MakeCartItemNameLink',
} as const;
